import React from "react"
import EventLayout from "../../components/layouts/eventLayout"
import { graphql } from "gatsby"
import Button from "../../components/button"

const WildlifeFestivalIndexPage = ({ data }) => {
  const tableRows = data.allWildlifeCsv.nodes.map(i => (
    <tr>
      <td>{i.year}</td>
      <td>{i.president}</td>
      <td>{i.snakes}</td>
    </tr>
  ))

  return (
    <EventLayout pageTitle="Claxton Rattlesnake & Wildlife Festival">
      <h2>Rattlesnake & Wildlife Festival – March 8 & 9, 2025.</h2>
      <br />
      <p>
        The Claxton Rattlesnake & Wildlife Festival, (first known as the Claxton
        Rattlesnake Roundup) actually began with a snakebite in 1967. That
        summer, a local youth, David Wiggins, was bitten by an Eastern
        Diamondback rattlesnake while picking vegetables on a farm in Evans
        County. Although there was no anti-venom available, David received
        competent medical attention and survived the bite; but the road to
        recovery took a full twelve months with lingering side effects. At this
        same time, it was fast becoming evident that Evans County had a huge
        rattlesnake population. Rattlers, always common throughout the woods and
        farms, were seen sunning themselves on the greens and sand traps of the
        local golf course and slithering across yards in town.
      </p>
      <br />
      <p>
        In February 1968, in an effort to reduce the rattlesnake population and
        prevent snakebite fatalities, the first Rattlesnake Roundup was staged.
        Forty-eight snakes were turned in, two short of the modest 50 snake
        goal.
      </p>
      <br />
      <p>
        In 1969, the Club decided to add “beauty with the beasts”. Miss Debbie
        Green was crowned as the first Rattlesnake Roundup Queen. Two
        hundred-twenty-one snakes were turned in for this Roundup.
      </p>
      <br />
      <p>
        Early events were held in Claxton in a local tobacco warehouse. Arts and
        craft vendors, food vendors, a grand parade, beauty pageant, essay
        contest, snake milking and handling demonstrations, and entertainment
        were added with the rattlesnakes to make this event a nationwide
        attraction.
      </p>
      <br />
      <p>
        In 2001, event Headquarters was moved to the newly constructed Evans
        County Wildlife Club facility, located just west of Claxton, Georgia,
        off Highway 280.
      </p>
      <br />
      <p>
        Although the tradition of catching rattlesnakes for competition and
        prizes is no longer a part of the festival (the event’s name was changed
        to Claxton Rattlesnake & Wildlife Festival in 2012), the slithery
        creatures are still one of the concentrations for this event. Wildlife
        conservation has become the focus in recent years with support from the
        Georgia Department of Natural Resources, Georgia Southern University,
        Orion Society, Jacksonville Zoo, and other organizations.
      </p>
      <br />
      <p>
        The Rattlesnake and Wildlife Festival is the greatest accomplishment of
        the Evans County Wildlife Club. The Southeast Tourism Society voted the
        Festival one of the “top Twenty Events in the Southeast”. The annual
        events brings over 12,000 tourists and an economic boost to the Evans
        County community.
      </p>
      <br />

      <p>
        For more information please visit{" "}
        <a href="https://www.ecwclub.org">www.ecwclub.org</a>.
      </p>

      <div css={{ paddingTop: `3rem` }}>
        {/* --   <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2023RattlesnakeRunSponsorshipForm.pdf">2023 Rattlesnake Run - Sponsor Form</a>
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2023RattlesnakeRunRegistrationForm.pdf">8th Annual Rattlesnake Run - Runner Registration</a> --*/}
      </div>
    </EventLayout>
  )
}

export const query = graphql`
  query WildLifeQuery {
    allWildlifeCsv {
      nodes {
        president
        snakes
        year
      }
    }
  }
`

export default WildlifeFestivalIndexPage
